// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comment-policy-js": () => import("./../../../src/pages/comment-policy.js" /* webpackChunkName: "component---src-pages-comment-policy-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/IndexPage.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-tag-index-page-js": () => import("./../../../src/templates/TagIndexPage.js" /* webpackChunkName: "component---src-templates-tag-index-page-js" */)
}

