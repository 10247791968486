import React from 'react'
import PropTypes from 'prop-types'

import HeatmapChart from './HeatmapChart'

import './Heatmap.scss'


// =============================================================================
const Heatmap = ({ caption, ...props }) => (
  <figure className="Heatmap">
    <HeatmapChart {...props} />
    {caption && <figcaption>{caption}</figcaption>}
  </figure>
)

// -----------------------------------------------------------------------------
Heatmap.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  caption: PropTypes.string,
  margin: PropTypes.shape({
    top:    PropTypes.number,
    right:  PropTypes.number,
    bottom: PropTypes.number,
    left:   PropTypes.number,
  }),
}

// -----------------------------------------------------------------------------
Heatmap.defaultProps = {
  margin: { top: 0, right: 0, bottom: 30, left: 35 },
}

// -----------------------------------------------------------------------------
export default Heatmap
