module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kier J. Dugan&rsquo;s Personal Blog","short_name":"Kier&rsquo;s Blog","start_url":"/","background_color":"#efefef","theme_color":"#111","display":"minimal-ui","icon":"src/images/site-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9e6fb5c85aa6b69eb6dfbd82d758ddf8"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-autolink-headers",{"resolve":"gatsby-remark-prismjs","options":{"inlineCodeMarker":"›","showLineNumbers":true}},{"resolve":"gatsby-remark-smartypants","options":{"dashes":"oldschool"}},{"resolve":"gatsby-remark-images","options":{"showCaptions":["title"],"disableBgImageOnAlpha":true}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"external nofollow noopener noreferrer"}},"gatsby-remark-numbered-footnotes"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0/apps/blog"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://kiers.blog/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
